// src/components/Header.js

import React, { useState } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';

function Header({ sports, setSelectedSport }) {
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleSportClick = (sportName) => {
    setSelectedSport(sportName);
    navigate('/'); // Navighează la pagina principală
    setIsMenuOpen(false);
  };

  // Funcție pentru a naviga la LiveScores
  const handleLiveScoresClick = () => {
    navigate('/live'); // Navighează la ruta LiveScores
    setIsMenuOpen(false);
  };

  return (
    <header className={isMenuOpen ? 'menu-open' : ''}>
      <div className="logo">
        <h1>Sport4You</h1> {/* Numele website-ului deasupra sporturilor */}
      </div>
      <button 
        className="menu-toggle" 
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        ☰
      </button>
      <nav>
        <ul>
          <li>
            <button onClick={handleLiveScoresClick} className="live-sport-button">
              LIVE Sport
            </button>
          </li>
          {sports.map((sport) => (
            <li key={sport.idSport} onClick={() => handleSportClick(sport.strSport)}>
              {sport.strSportIconGreen && (
                <img src={sport.strSportIconGreen} alt={sport.strSport} className="sport-icon" />
              )}
              {sport.strSport}
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
