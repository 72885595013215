import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import './EventDetails.css';
import LinkForm from './LinkForm'; // Formular pentru adăugare link
import moment from 'moment-timezone'; // Importă moment-timezone

function EventDetails() {
  const { id } = useParams(); // Obține ID-ul evenimentului din URL
  const [event, setEvent] = useState(null);
  const [teams, setTeams] = useState([]); // Stochează echipele din ligă
  const [links, setLinks] = useState([]); // Stochează linkurile asociate evenimentului
  const [tvChannels, setTvChannels] = useState([]); // Stochează canalele TV
  const [localTime, setLocalTime] = useState(''); // Stochează ora locală
  const [europeanTimes, setEuropeanTimes] = useState({}); // Stochează orele în diferite țări europene

  const predefinedKey = 'Florin2024'; // Cheia predefinită

  // Funcție pentru a verifica cheia din localStorage sau pentru a cere cheia
  const checkKey = async () => {
    const savedKey = localStorage.getItem('event_key');
    if (!savedKey) {
      const { value: inputKey } = await Swal.fire({
        title: 'Introduceți cheia predefinită',
        input: 'password',
        inputPlaceholder: 'Introduceți cheia',
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return 'Trebuie să introduceți o cheie!';
          } else if (value !== predefinedKey) {
            return 'Cheia este incorectă!';
          }
        }
      });
      if (inputKey === predefinedKey) {
        localStorage.setItem('event_key', inputKey);
        return true;
      } else {
        return false;
      }
    } else if (savedKey === predefinedKey) {
      return true;
    } else {
      localStorage.removeItem('event_key');
      return checkKey();
    }
  };

  // Funcție pentru a obține detaliile evenimentului
  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const response = await axios.get(`https://www.thesportsdb.com/api/v1/json/112634/lookupevent.php?id=${id}`);
        setEvent(response.data.events[0]); // Presupunând că API-ul returnează un array de evenimente
      } catch (error) {
        console.error('Eroare la obținerea detaliilor evenimentului:', error);
      }
    };
    fetchEventDetails();
  }, [id]);

  // Funcție pentru a obține echipele și linkurile asociate
  useEffect(() => {
    const fetchTeamsAndLinks = async () => {
      if (event) {
        // Obține echipele din ligă
        try {
          const teamsResponse = await axios.get(`https://www.thesportsdb.com/api/v1/json/112634/lookup_all_teams.php?id=${event.idLeague}`);
          setTeams(teamsResponse.data.teams || []);
        } catch (error) {
          console.error('Eroare la obținerea echipelor:', error);
        }

        // Obține linkurile asociate acestui eveniment
        try {
          const linksResponse = await axios.get(`/app/events/${event.idEvent}/links`);
          setLinks(linksResponse.data);
        } catch (error) {
          console.error('Eroare la obținerea linkurilor:', error);
        }

        // Obține ora locală bazată pe IP
       // Obține ora locală bazată pe IP
// Obține ora locală bazată pe IP
try {
  const ipResponse = await axios.get('https://ipapi.co/json/');
  const userTimezone = ipResponse.data.timezone;

  // Verifică dacă datele evenimentului sunt disponibile
  if (event && event.dateEvent && event.strTime) {
    const eventDateTimeUTC = `${event.dateEvent}T${event.strTime}`; // Asigură-te că ora este în format UTC
    console.log('Event Date and Time in UTC:', eventDateTimeUTC);

    // Setează ora locală pentru utilizator
    const eventTime = moment.utc(eventDateTimeUTC).tz(userTimezone).format('HH:mm');
    setLocalTime(eventTime);

    // Conversia timpului pentru diverse țări europene
    const europeanTimezones = {
      'România': 'Europe/Bucharest',
      'Germania': 'Europe/Berlin',
      'Franța': 'Europe/Paris',
      'Italia': 'Europe/Rome',
      'Spania': 'Europe/Madrid',
      'Marea Britanie': 'Europe/London',
      'Olanda': 'Europe/Amsterdam',
      'Polonia': 'Europe/Warsaw',
      'Belgia': 'Europe/Brussels',
      'Austria': 'Europe/Vienna',
      'Suedia': 'Europe/Stockholm',
      'Norvegia': 'Europe/Oslo',
      'Danemarca': 'Europe/Copenhagen',
      'Finlanda': 'Europe/Helsinki',
      'Cehia': 'Europe/Prague',
      'Ungaria': 'Europe/Budapest',
      'Slovacia': 'Europe/Bratislava',
      'Elveția': 'Europe/Zurich',
      'Grecia': 'Europe/Athens',
      'Portugalia': 'Europe/Lisbon',
      'Irlanda': 'Europe/Dublin',
    };

    const times = {};
    for (const [country, timezone] of Object.entries(europeanTimezones)) {
      const convertedTime = moment.utc(eventDateTimeUTC).tz(timezone).format('HH:mm');
      times[country] = convertedTime; // Salvează ora convertită pentru fiecare țară
      console.log(`${country}: ${convertedTime}`); // Afișează timpul convertit
    }
    setEuropeanTimes(times);
  } else {
    console.error('Datele evenimentului nu sunt disponibile.');
  }

} catch (error) {
  console.error('Eroare la obținerea informațiilor IP:', error);
}


        
      }
    };
    fetchTeamsAndLinks();
  }, [event]);

  // Obține canalele TV
  useEffect(() => {
    const fetchTvChannels = async () => {
      if (event) {
        try {
          const response = await axios.get(`/app/tv_azi/${event.idEvent}`);
          setTvChannels(response.data.events || []); // Folosim cheia 'tvevent'
        } catch (error) {
          console.error('Eroare la obținerea canalelor TV:', error);
        }
      }
    };

    fetchTvChannels();
  }, [event]);

  // Funcție pentru a salva un link
  const handleLinkSubmit = async (link) => {
    const isKeyValid = await checkKey();
    if (isKeyValid) {
      try {
        await axios.post('/app/events/link', {
          eventId: event.idEvent,
          link: link
        });
        // Actualizează lista de linkuri după salvarea cu succes
        const updatedLinksResponse = await axios.get(`/app/events/${event.idEvent}/links`);
        setLinks(updatedLinksResponse.data);
      } catch (error) {
        console.error('Eroare la salvarea linkului:', error);
      }
    }
  };

  // Funcție pentru a șterge un link specific
  const handleLinkDelete = async (linkToDelete) => {
    const isKeyValid = await checkKey();
    if (isKeyValid) {
      Swal.fire({
        title: 'Sigur doriți să ștergeți acest link?',
        text: "Această acțiune nu poate fi anulată!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da, șterge-l!',
        cancelButtonText: 'Anulează'
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await axios.post('/app/delete_events/link', {
              eventId: event.idEvent,
              link: linkToDelete
            });
            // Actualizează lista de linkuri după ștergere
            const updatedLinksResponse = await axios.get(`/app/events/${event.idEvent}/links`);
            setLinks(updatedLinksResponse.data);
            Swal.fire('Șters!', 'Linkul a fost șters cu succes.', 'success');
          } catch (error) {
            console.error('Eroare la ștergerea linkului:', error);
            Swal.fire('Eroare!', 'A apărut o eroare la ștergerea linkului.', 'error');
          }
        }
      });
    }
  };

  // Verifică dacă evenimentul este disponibil
  if (!event) {
    return <p>Evenimentul nu este disponibil.</p>;
  }

  const homeTeamPosition = teams.findIndex(team => team.strTeam === event.strHomeTeam);
  const awayTeamPosition = teams.findIndex(team => team.strTeam === event.strAwayTeam);

  const formatDate = (dateString) => {
    if (!dateString) return 'Data nu este disponibilă';
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatTime = (timeString) => {
    if (!timeString) return 'Ora nu este disponibilă';
    const [hours, minutes] = timeString.split(':');
    return `${hours}:${minutes}`;
  };

  return (
    <div className="event-details">
      <h2>{event.strEvent}</h2>
      <p>Data: {formatDate(event.dateEvent)}  Ora: {formatTime(event.strTime)}</p>
      <p>Ora locală: {localTime}</p> {/* Afișează ora locală */}

      {/* Afișare ore în diferite țări europene */}
      <h3>Orele în diferite țări europene:</h3>
      <table className="country-time-table">
        <thead>
          <tr>
            <th>Țară</th>
            <th>Ora</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(europeanTimes).map(([country, time], index) => (
            <tr key={country}>
              <td>{country}</td>
              <td>{time}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Detalii despre echipe */}
      <h3>Echipele</h3>
      <div className="team-details">
        <div className="team">
          <h4>{event.strHomeTeam}</h4>
          <p>Loc: {homeTeamPosition !== -1 ? homeTeamPosition + 1 : 'N/A'}</p>
        </div>

        <div className="team">
          <h4>{event.strAwayTeam}</h4>
          <p>Loc: {awayTeamPosition !== -1 ? awayTeamPosition + 1 : 'N/A'}</p>
        </div>
      </div>

      {/* Afișare canale TV */}
      <h3>Canale TV care transmit evenimentul</h3>
      <div className="tv-channels-container">
        {tvChannels.length > 0 ? (
          tvChannels.map((channel, index) => (
            <div key={index} className="tv-channel">
              <img src={channel.strLogo} alt={channel.strChannel} className="tv-channel-logo" />
              <p>{channel.strChannel}</p>
            </div>
          ))
        ) : (
          <p>Nu există informații despre canalele TV care transmit acest eveniment.</p>
        )}
      </div>

      {/* Afișează toate echipele din ligă într-un tabel */}
      <h3>Clasamentul echipelor din ligă</h3>
      <table className="league-table">
        <thead>
          <tr>
            <th>Loc</th>
            <th>Echipa</th>
          </tr>
        </thead>
        <tbody>
          {teams.length > 0 ? (
            teams.map((team, index) => {
              const isHomeTeam = team.strTeam === event.strHomeTeam;
              const isAwayTeam = team.strTeam === event.strAwayTeam;
              const rowClass = isHomeTeam || isAwayTeam ? 'highlight-team' : '';

              return (
                <tr key={team.idTeam} className={rowClass}>
                  <td>{index + 1}</td>
                  <td>{team.strTeam}</td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="2">Nu am găsit echipe în ligă.</td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Afișare linkuri salvate */}
      <h3>Vizionează acest eveniment</h3>
      <div className="link-container">
        {links.length > 0 ? (
          links.map((link, index) => (
            <div className="link-card" key={index}>
            <a href={link.link} target="_blank" rel="noopener noreferrer">
              {`Vizionează evenimentul link ${index + 1}`}
            </a>
            {/* Afișează butonul doar dacă utilizatorul are cheia corectă în localStorage */}
            {localStorage.getItem('event_key') === predefinedKey && (
              <button className="delete-link-button" onClick={() => handleLinkDelete(link.link)}>
                Șterge Link
              </button>
            )}
          </div>
          ))
        ) : (
          <div className="link-card">
            <p>Nu au fost salvate linkuri pentru acest eveniment.</p>
          </div>
        )}
      </div>

      {/* Formular pentru introducerea linkurilor */}
      <LinkForm onLinkSubmit={handleLinkSubmit} />
    </div>
  );
}

export default EventDetails;
