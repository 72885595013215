import React from 'react';
import './LeagueDetails.css';
import { useNavigate } from 'react-router-dom';

function LeagueDetails({ events }) {
  const navigate = useNavigate();

  const handleEventClick = (event) => {
    // Navighează la detaliile evenimentului
    navigate(`/event/${event.idEvent}`, { state: { event } });
  };

  // Obiect pentru a asocia țările cu fusurile lor orare
  const timeZones = {
    'Romania': 'Europe/Bucharest',
    'Germany': 'Europe/Berlin',
    'France': 'Europe/Paris',
    'Italy': 'Europe/Rome',
    'Spain': 'Europe/Madrid',
    'UK': 'Europe/London',
    'Netherlands': 'Europe/Amsterdam',
    // Adaugă alte țări europene aici
  };

  return (
    <div className="league-details">
      {events.map(event => {
        // Verifică dacă event.strTimestamp există și este valid
        const eventDateUTC = event.strTimestamp ? new Date(event.strTimestamp) : null;

        // Formatează data doar dacă eventDateUTC este valid
        const formattedDate = eventDateUTC 
          ? eventDateUTC.toLocaleDateString('ro-RO', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
          : 'Date not available'; // Mesaj alternativ dacă data este invalidă

       
        // Adăugăm 3 ore la ora UTC pentru a obține ora locală
        const utcPlus3 = eventDateUTC 
          ? new Date(eventDateUTC.getTime() + 3 * 60 * 60 * 1000) // Adăugăm 3 ore în milisecunde
          : null;

        // Obține ora pentru fiecare țară, adăugând 3 ore
        const timesByCountry = Object.entries(timeZones).map(([country, timeZone]) => {
          // Obținem ora locală pe baza fusului orar +3 ore
          const localDateTime = eventDateUTC 
            ? new Date(eventDateUTC.getTime() + 3 * 60 * 60 * 1000) // Adăugăm 3 ore în milisecunde
            : null;

          return {
            country,
            localTime: localDateTime 
              ? new Intl.DateTimeFormat('ro-RO', {
                  timeZone,
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }).format(localDateTime)
              : 'Time not available'
          };
        });

        return (
          <div key={event.idEvent} className="event" onClick={() => handleEventClick(event)}>
            <h3>{event.strEvent}</h3>

            {/* Verificăm dacă event.strThumb există și adăugăm imaginea */}
            {event.strThumb && (
              <img 
                src={event.strThumb} 
                alt={`Thumbnail for ${event.strEvent}`} 
                className="event-thumb" // Asigură-te că adaugi stilul pentru imagine în CSS
              />
            )}

            <p>Data: {formattedDate} Ora: {utcPlus3 
              ? new Intl.DateTimeFormat('ro-RO', {
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                }).format(utcPlus3) 
              : 'Time not available'}</p>

            <h4>Ore de început pentru diferite țări:</h4>
            <ul>
              {timesByCountry.map(({ country, localTime }) => (
                <li key={country}>{country}: {localTime}</li>
              ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export default LeagueDetails;
