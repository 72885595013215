import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Folosește useNavigate în loc de useHistory
import './LiveScores.css'; // Asigură-te că ai acest fișier CSS pentru stilizare

const LiveScores = () => {
  const [scores, setScores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSport, setSelectedSport] = useState('');
  const [selectedLeague, setSelectedLeague] = useState('');
  const [leagues, setLeagues] = useState({});
  const navigate = useNavigate(); // Folosește useNavigate pentru navigare

  const fetchLiveScores = async () => {
    try {
      const response = await axios.get('/app/livescores');
      setScores(response.data.livescore || []); // Extragem array-ul 'livescore'
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLiveScores();
  }, []);

  // Grupăm scorurile pe sporturi
  const groupedScores = scores.reduce((acc, event) => {
    const sport = event.strSport;
    if (!acc[sport]) {
      acc[sport] = [];
    }
    acc[sport].push(event);
    return acc;
  }, {});

  // Creăm lista de ligi în funcție de sport
  const handleSportChange = (sport) => {
    setSelectedSport(sport);
    setSelectedLeague(''); // Resetăm liga selectată
    setLeagues(groupedScores[sport]);
  };

  // Filtrăm competițiile în funcție de ligă selectată
  const filteredCompetitions = selectedLeague
    ? leagues.filter(event => event.strLeague === selectedLeague)
    : leagues;

  const handleEventClick = (eventId) => {
    // Navigăm către pagina evenimentului folosind idEvent în loc de idLiveScore
    navigate(`/event/${eventId}`); // Folosește navigate pentru a trimite idEvent
  };

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="live-scores">
      <h1>Live Sports Scores</h1>

      {/* Dropdown pentru sporturi */}
      <select onChange={(e) => handleSportChange(e.target.value)} value={selectedSport}>
        <option value="">Select a sport</option>
        {Object.keys(groupedScores).map((sport) => (
          <option key={sport} value={sport}>
            {sport}
          </option>
        ))}
      </select>

      {/* Dropdown pentru ligi */}
      {selectedSport && (
        <select onChange={(e) => setSelectedLeague(e.target.value)} value={selectedLeague}>
          <option value="">Alege Liga</option>
          {Array.from(new Set(leagues.map(event => event.strLeague))).map((league) => (
            <option key={league} value={league}>
              {league}
            </option>
          ))}
        </select>
      )}

      {/* Lista competițiilor filtrate */}
      {filteredCompetitions && filteredCompetitions.length > 0 ? (
        <ul className="competition-list">
          {filteredCompetitions.map((event) => (
            <li 
              className="competition-item" 
              key={event.idEvent} // Folosim idEvent pentru cheie
              onClick={() => handleEventClick(event.idEvent)} // Folosim idEvent pentru navigare
            >
              <div className="competition-details">
                <img
                  src={event.strHomeTeamBadge}
                  alt={`${event.strHomeTeam} logo`}
                  className="team-badge"
                />
                <span className="team-name">{event.strHomeTeam}</span>
                <span className="score">
                  {event.intHomeScore ?? 0} - {event.intAwayScore ?? 0}
                </span>
                <img
                  src={event.strAwayTeamBadge}
                  alt={`${event.strAwayTeam} logo`}
                  className="team-badge"
                />
                <span className="team-name">{event.strAwayTeam}</span>
               
              </div>
              <div className="competition-status">
                <span className="minute">Minutul: {event.strProgress}</span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default LiveScores;
