import React, { useState } from 'react';
import LeagueDetails from './LeagueDetails';
import { useNavigate } from 'react-router-dom';
import './SportEvents.css';

function SportEvents({ events }) {
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // State pentru căutare
  const navigate = useNavigate();

  if (!events || events.length === 0) {
    return <p>Nu sunt Evenimente Disponibile...</p>;
  }

  // Obținem ligile din evenimente
  const leagues = events.reduce((acc, event) => {
    if (!acc[event.strLeague]) {
      acc[event.strLeague] = [];
    }
    acc[event.strLeague].push(event);
    return acc;
  }, {});

  // Filtrăm ligile pe baza searchTerm
  const filteredLeagues = Object.keys(leagues).filter(league => 
    league.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleEventClick = (event) => {
    navigate(`/event/${event.idEvent}`, { state: { event } });
  };

  return (
    <div className="sport-events">
      {/* Input pentru căutare */}
      <input
        type="text"
        placeholder="Caută ligile..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Actualizăm state-ul pe baza input-ului
        className="search-input"
      />

      {filteredLeagues.length > 0 ? (
        filteredLeagues.map(league => (
          <div key={league} className="league">
            <h2 className="league-title" onClick={() => setSelectedLeague(league)}>
              {league}
            </h2>
            {selectedLeague === league && (
              <LeagueDetails 
                events={leagues[league]} 
                searchTerm={searchTerm} // Trimitem searchTerm la LeagueDetails
              />
            )}
          </div>
        ))
      ) : (
        <p>Nu există ligi care să corespundă căutării...</p>
      )}
    </div>
  );
}

export default SportEvents;
