// LinkForm.js
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

function LinkForm({ onLinkSubmit }) {
  const [link, setLink] = useState('');
  const predefinedKey = 'Florin2024'; // Cheia predefinită
  const storedKey = localStorage.getItem('authKey'); // Obține cheia din Local Storage

  const handleAddLinkClick = async () => {
    // Dacă cheia nu este salvată în Local Storage, cere-o de la utilizator
    if (!storedKey) {
      const { value: key } = await Swal.fire({
        title: 'Introduceti cheia',
        input: 'text',
        inputLabel: 'Cheie de autorizare',
        inputPlaceholder: 'Introduceti cheia',
        showCancelButton: true,
        confirmButtonText: 'Adaugă link',
        cancelButtonText: 'Anulează',
        inputValidator: (value) => {
          if (!value) {
            return 'Trebuie să introduci o cheie!';
          }
          // Verifică dacă cheia este corectă
          if (value !== predefinedKey) {
            return 'Cheia introdusă este greșită!';
          }

          // Salvează cheia în Local Storage
          localStorage.setItem('authKey', value);
        },
      });

      // Dacă utilizatorul a introdus cheia corect
      if (key === predefinedKey) {
        if (link) {
          onLinkSubmit(link);
          setLink(''); // Resetare câmp
        } else {
          Swal.fire('Te rog să introduci un link valid.');
        }
      }
    } else {
      // Dacă cheia este deja salvată, adaugă linkul direct
      if (link) {
        onLinkSubmit(link);
        setLink(''); // Resetare câmp
      } else {
        Swal.fire('Te rog să introduci un link valid.');
      }
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Introduceti linkul"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        required
      />
      <button type="button" onClick={handleAddLinkClick}>Adaugă link</button>
    </div>
  );
}

export default LinkForm;
