import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import SportEvents from './components/SportEvents';
import EventDetails from './components/EventDetails';
import LiveScores from './components/LiveScores';

function App() {
  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [events, setEvents] = useState([]);

  // Obține data curentă în format YYYY-MM-DD
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // lunile sunt indexate de la 0
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  // Fetch sports from your local API (Node.js & MariaDB)
  useEffect(() => {
    axios.get('/app/sports')  // URL-ul tău local
      .then(response => setSports(response.data))  // `response.data` conține lista de sporturi din API-ul local
      .catch(error => console.log('Error fetching sports:', error));
  }, []);

  // Fetch events for the selected sport
  useEffect(() => {
    if (selectedSport) {
      const currentDate = getCurrentDate(); // Obține data curentă
      axios.get(`https://www.thesportsdb.com/api/v1/json/112634/eventsday.php?d=${currentDate}&s=${selectedSport}`)
        .then(response => {
          if (response.data && response.data.events) {
            setEvents(response.data.events);
          } else {
            setEvents([]);  // Dacă nu sunt evenimente, setează un array gol
          }
        })
        .catch(error => {
          setEvents([]);  // În caz de eroare, setează un array gol
        });
    }
  }, [selectedSport]);

  return (
    <Router>
      {/* Transmitem variabilele ca props către AppWithLocation */}
      <AppWithLocation 
        sports={sports} 
        setSelectedSport={setSelectedSport} 
        selectedSport={selectedSport} 
        events={events} 
      />
    </Router>
  );
}

// Componenta AppWithLocation primește props-urile necesare
function AppWithLocation({ sports, setSelectedSport, selectedSport, events }) {
  const location = useLocation(); // Se folosește în interiorul Router-ului pentru a urmări schimbările de rută

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-8EWRX9V9QD', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return (
    <div className="App">
      <Header sports={sports} setSelectedSport={setSelectedSport} />
      <Routes>
        {/* Ruta pentru pagina principală care afișează evenimentele */}
        <Route path="/" element={selectedSport ? <SportEvents events={events} /> : <div>Select a sport to view events.</div>} />
        {/* Ruta pentru detalii despre un eveniment */}
        <Route path="/event/:id" element={<EventDetails />} />
        <Route path="/live" element={<LiveScores />} />
      </Routes>
    </div>
  );
}

export default App;
